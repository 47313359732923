import { createContext, useState } from "react";

export const Contextone = createContext(null);

export const CounterProvider = (props)=>{

    const[isLogged, setIsLogged] = useState(false);
    const[isLogoutButton, setIsLogOutButton] = useState(false);
    const[name,setName] = useState(false);
    const [isPremium, setisPremium] = useState(false);
    const [isTid, setIsTid] = useState(1);
    const [isHomeId, setIsHomeId] = useState(1);
    const [isDomain, setDomain] = useState("");
    const [isLogo, setLogo] = useState("");
    const [isTitle, setTitle] = useState("");
    
    const [showNewsContent, setShowNewsContent] = useState('');

    return(
        <Contextone.Provider value ={{setShowNewsContent,showNewsContent,isDomain,setDomain,setLogo,isLogo, setTitle,isTitle, isLogged, setIsLogged, isLogoutButton, setIsLogOutButton,name, setName, isPremium, setisPremium, isTid, setIsTid, isHomeId, setIsHomeId}}>
        {props.children}
        </Contextone.Provider>
    )
}